<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Edit Profile
        </h1>
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/profile" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <TextInput type="text" label="Email" v-model="item.email" :required="false" ></TextInput>
                <TextInput type="password" label="New Password" v-model="item.new_password" :required="false" ></TextInput>
                <TextInput type="password" label="Confirm New Password" v-model="item.new_password_confirmation " :required="false" ></TextInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import Axios from 'axios'
export default {
    name: 'Profile',
    mixins: [View],
    data: () => ({
        parameter: false,
        action: '/profile/',
    }),
    computed: {
        formData() {
            return {
                'user':this.item,
            }
        },
    },
    props: [],
    methods: {
        roleDisplay(item) {
            return item.name;
        },
        roleSelected(evt) {
            let index = this.item.roles.findIndex((role)=>{return role.id == evt.item.id})
            if(index < 0) this.item.roles.push(evt.item);
        },
        roleRemoved(evt) {
            this.item.roles.splice(evt.index,1);
        },
        LinkAsana() {
            Axios.get('/profile/asana').then(re=>{
                if(re.data.status == 'OK'){
                    window.location = re.data.url
                }
            })
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
